<template>
  <public-layout>
    <div class="container mx-auto">
      <div class="flex flex-col justify-center px-4">
        <h1 class="text-2xl font-bold mb-4 text-center">
          ¿QUIERES ELIMINAR TU CUENTA?
        </h1>
        <section class="">

          <div class="mb-8 text-center">
            Envianos un correo a <a class="text-purple-400" href="mailto:info@Samiiweb.com">info@Samiiweb.com</a>, y en un lapso menor a 24 horas tu cuenta estara eliminada.

          </div>

        </section>
      </div>
    </div>
  </public-layout>
</template>
<script>
import PublicLayout from '@/components/layouts/PublicLayout';

export default {
  name: 'PrivacyPolicy',
  components: {
    PublicLayout,
  },
  methods: {
    redirect(url) {
      window.open(url, '_blank');
    },
  },
}
</script>
