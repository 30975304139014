<template>
  <div>
    <div class="container flex flex-col flex-wrap items-center p-5 mx-auto md:flex-row">
      <samii-logo />
      <nav
        class="flex flex-wrap items-center justify-center space-x-4 text-md md:ml-auto">
        <a class="text-gray-500 no-underline mb-2" @click="goto('features')">
          Características
        </a>
        <a class="text-gray-500 no-underline mb-2" @click="goto('pricing')">
          Precios
        </a>
        <a
          class="text-gray-500 no-underline mb-2"
          @click="$router.push({ path: '/login' })">
          Iniciar sesión
        </a>
        <a
          class="text-gray-500 no-underline"
          @click="$router.push({ path: '/register' })">
          <span class="px-3 py-2 text-purple-600 bg-purple-200 border-purple-600 rounded text-md hover:text-white hover:bg-purple-500">
            Regístrate
          </span>
        </a>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TheHeader',
  components: {
    SamiiLogo: () => import('@/components/atoms/SamiiLogo.vue'),
  },
  methods: {
    goto(id) {
      const element = document.getElementById(id);
      const top = element.offsetTop;
      window.scrollTo(0, top);
    },
  },
}
</script>
